import { ENV } from "../utils/environment";
import config from "../config/config";
import Rpc from "../rpc/Rpc";
import { ethers } from "ethers";
import axios from "axios";
import React, { createContext, useContext, useCallback, useState, useEffect } from "react";

import { shaMessage } from "../utils/signature";
import { toast } from "react-toastify";

import BigNumber from "bignumber.js";

import { useWeb3Context } from "./Web3Context";

import PlayerModel from "../models/PlayerModel";
import NftModel from "../models/NftModel";
import CollectionModel from "../models/CollectionModel";

import { getOneOfEachSpecies } from "../utils/nft";

const playersToCheckOn = {
  none: null,
  CottiniKC: "0x64d28a873B10E865B100727817141F9dcF55FCAd", // 90
  Sharkymate: "0x5e50e80394e5e0754d7771e7d76200bee7d6ecce", // 35
  Jonhing: "0x01195352AB9A6fc08FCc69e796b30dDbAE771cfF", // 5
  Jonhing2: "0x1919d085CDe76ee8caded61Bd221BB461fD0D168",
  Jonhing3: "0x7b8C6c4Af9131E9BA2871e9671F176938a470e2A",
  Meinrath: "0x54Bdf69fE64CeCe40eb348CE006B46cb4A87eD84", // 4
  Meinrath2: "0x7d9f9eC03bb80Da139bc2825e07f607A46EB7abA", // 4
  Cartola: "0xec28e76da54c5d4098bcf5389a7b76ce2cf293ab", // 2
  Myk: "0x68efdc03576784959bb5e70f2bb96d319dd2353b", // 1
  Myk2: "0x0F740aA14B93fdD825D92ce99d55EE3a58549516",
  Myk3: "0x1E62c8f5aAd116728d51Eca1b7c81EdeE10F0edc",
  Lucas: "0xae328e95ab3f3d7f804004ebffba755222fddad6", // 0
  Cottini: "0x8aff95b085e721b91c8158258438da058b6d9c97",
  Rafa: "0x07aDa45d0bF66008D3a1edb479A9DCD2ef18D150",
  Rodion: "0x775D15a702AEF3730AB3D269b660Ad94ee456Cf3",
  Raul: "0x9d590b7AE79ee629d1fDf08b84B637313850223b",
  Oleran: "0x0e47cc52E41446C6D66f0c3e464E93383B079E4E",
  TucoBsb: "0xB530435603a1442cb5c323dd9068a9c863Eb27B6",
  TucoBsb2: "0x08874753D9F3DF4cc3813CbC91DeeB81024B93CA",
  Sindelitcis: "0xA8Ce165914dc76F06EF5E9bcbA7DFE01BFe6a94e",
  Lex: "0x658A7555fD8798c9482ecD6A7B8ecc188b7eCa37",
  iDrJoe: "0xD4C45581b900212A22d042c926F26478AE2Ae085",
  iDrJoe2: "0xC99E69b533AB5A761Cf951356A1AE318140d7eA4",
  BioniX: "0xf1b225558c508206ca284228aa8022344cd9f0a4",
  Mabesi: "0xc02af80881f87e39cd413cb12e37e7c1198dd8e2",
  Mabesi2: "0x372745EfC5b395ECf4Bf45b8b55Af7054E924a01",
  YEP: "0xa9d5f13354a247a65e335ba40660f46817c9dd9e",
  YEP2: "0xC4903a055eB1D952a3F52B54A4BFd87CeA697794",
  YEP3: "0xc5DeD82e2Fc282cf02c5721177D164E9448a1041",
  Sucre: "0x3eea6556ba2874e7b102bab7075001ecd8624446",
  Bagkeo: "0x35686dafc5d189a8e0b30b360380c9e57a0a8413",
  Cottini2: "0x489200837C9466d21Dc879c2C9Ae8B82A8926990",
  Cottini4: "0xEA7d1B0F1E4863f590CF9e646ea980fA88758500",
  Cottini5: "0x26a31be3ef4B23Faad4685507F24F616CEac200B",
  Cottini6: "0xb77860f6D0A5F80d4e2779dE4a4F19bB1b0C2995",
  Rafa2: "0x7cDA6841214a7c64EC13eAeD059eCB6499929f0a",
  Rafa3: "0xDb729c374d60c99e908105561AA9789E396c7960",
  Meinrath2: "0x0d6C1760348398eaAd0D4E3F251A3f04140a6597",
  LucasSalas: "0x4Cc7acF459607A59816217f8c107a2ec0196f6ca",
  KhalAltair: "0x63d8cd83ec4a41ee334072630d955d9d9175545a",
  Birl: "0x7E91ba2Eaf692CBb79ace422693d7F9a529c029A",
  Brandon: "0x1C66862773a45bC95Ec302EFc50E44F9fA9E4258",
  Marvel: "0xb3e2b9300D6d78622df8f89B904c2d4e5A1da70A",
  Marvel2: "0x6c3f1e47F2326eA3fc49B88922192F9348983EdA",
  Marvel3: "0xc3e43F12577ECbB276c01aEF3f2F4894AeA2Fd4C",
  TiagoB: "0xB4d07203D04B01Ea3DA042A160Ec364d57d51c33",
  Vands10: "0xC23De282e221fC9976f509CD13cF7DdA7C49bB97",
  Vands102: "0xE2841043A2135590b7658CC081D1e4537feC6C2c",
  Halfeld: "0xDfc68F0f5004dF11A243f4aA482557C49e82f6EA",
  Mrk80NL: "0x5652521FC7826d13bd850f26871Be02dB68CB35E",
  VinnyRK: "0xa554dD26144F382f0eC30b5121C3477Fffa94593",
  Adriano: "0x8D06407E4f31a89702A038117cEDd6a0b31Db68E",
  MrCruz: "0x1f01BD39c3795251c399d82E0f434d2346a7bD33",
  MrCruz2: "0x80AE6209802b92505F42F144C4D5844b601F9860",
  KennethWE: "0x25d4130c3c3d9e69c36c21cc9dcaf32865ee8bc6",
  WhoKnows: "0xd31b0bbc3034d4695b7cee7d77228f811d16e76c",
  Rickard: "0xA3f1eFa67457D7D6E860B96E1B39cC463B5893b9",
  Tristan: "0x2374b26130fbea9765848d23c1ab2e85f38ec0b7",
  Snake7: "0xe2e002145AD4eEf06727C3F724954e9625186D7c",
  Snake7B: "0xf8d8F873b00248411E7DfF9daf324967417958e4",
  Paradigma: "0x7aDf30DCa721F792a4308dcaC6607117e697a432",
  EduZRA: "0x2e30518b5E73E01c2Bdc51eA16279D6dC071b4E8",
  Croata: "0x54e01b63c88E677022447c7B1E83A8BA83a75B4F",
  Barbos: "0x34e9a3458492a0aa0ea082d7ece8f07f896ed44a",
  Kaiony: "0x5c5974d4f6a173dd06e089848866f7556d926d8f",
  TurboChicho: "0xACEBf2830f26c464a705a037A3Fd557359eF08aA",
  tafelwart: "0xf46828aec8e052b9ef4656dbae495048e2f9ad96",
  LanGee: "0xF5540cBaa1859e370D56A714dB63A8c52Ad3F1D2",
  Enjo: "0xCc4bc64474794CDeeFf6f8a803B8DF751213Cc52",
  Rmzndndr: "0xf6110E57ca3853A3d396F83404eEDd3C749061B7",
  Hidden: "0x628cd54b87742ab70fe62c6f41df01ee5691d871",
  Castor: "0xab2b2a5b4821c49ed825deeb70eb99c782e4df12",
  Shrek: "0xefa38d948bf00698171e3d22d4c997585a124f71",
  KuroSawara: "0xfCAfd863FD9c248aea4A34853E5C30a9d9CA42f9",
  Wishmeluck: "0xb372c09c64e54f9850909b95d5f126738ea216c3",
  SleaveOfSpeak: "0xd6192e3310a0a0beb76385b4d07e14a960f5ebd0",
  Another: "0x586867ca5d54ead26b8f6d6dd4dad38a8bcb942e",
  EbenezerWS: "0xfafb66028a3cd56624580c4503520f850c780ad8",
  iDrJoe3: "0x96877Aef3658cC5D42dA3561Cb663545C270f47d",
  iDrJoe4: "0xC99E69b533AB5A761Cf951356A1AE318140d7eA4",
  Ezequias: "0xD6C32dD22570a46A7B6D09f618568262C55D9d6C",
  Meinrath3: "0xc8722c29cb18a8556d227e88ef3db0530827b591",
  Abrudandaniel: "0x8cB618De00BF47437f8FBd4e96f8FfE3F8Ebd800",
  Abrudandaniel10: "0x645DdF747B300997c629b2A0fA091A46CC43CD00",
  _370X: "0x0Fc18A7C42A7aC336C936DF675B6F2c1C08ad994",
  _370X2: "0xf8BFC2f9db6e5fa8004396dd6c180927aB51534E",
  Artymis: "0x0892a0a103a2df41EbF3C50572EcD0b948dFFee2",
  Andreik: "0xa0eB3b52C0215a3315133Def45631c20F11FAd59",
  Keese: "0x1908987c1D9c2872F10373DFCd9E8cB2C750f0C9",
  Diego: "0x631b947Bd2ca27a6e39Ab73c654951D59026e382",
  n1kghambash: "0x521780fd4b6e7903b680ab858523bf016d820997",
  UnpaidMechanic: "0x822ea23057aC617de4B6c85156b81829fae30e1A",
  Danilorde: "0x5A9ec1dF20130f0fcAE39E2C1828B649EEe2960a",
  MellowFellow: "0xEA9192c839293b3F798cfE03d7DFE3FfeB10783E",
  Dankinator: "0x6936500e7D5E13Edc57C56644D3b357b478499bf",
  M4rtr1x: "0x3396A212719eD155dde3d3c3aF9BCF45F7D3BF58",
  Al3xP: "0x4fCAeF9f751AA4d47d0D8d39A174d268661A0c6D",
  GLopes: "0xd932e39fd1638fd7f02681b74b0c6e8918e1b335",
  Bandit: "0x022D302f60240D497406905DC4c011125d1590C5",
  Rody: "0xC4AB3fC20cb6f70EdF34f92b6d385655d68323E1",
  XTShot: "0x282FA7460D6Da70acf5369ccB7223aB3446bF3F0",
  Darkside: "0xF103ef254B5FEb78807f53ee1ABB55618F608ab0",
  AndreFerreira: "0x8e5a9b97ff9c90bdd738bb3a4afbb006139b3487",
  Kratos: "0x6EcAf8472E2ce398BBaB9eB45CcECE5bd82f462D",
  Goldy417Pixel: "0xd8d0c63F7E61b23d874f7afD4324C7c29aC7205C",
  NaoAcordaANinaIg: "0xB546bDaECd1cd9266175EacC17F21B8820310139",
  SuperFenix: "0x4e912EB29b244eADcd589B0080230B25e61eE990",
  Jib: "0xEA32a7Fe541547e11FbC5c24d1183Da2D31F6541",
  Tncv: "0x7215c9CcD3fa7c4e6dB1fF3aca45b99E61aa202c",
  YPC7Snake: "0xe2e002145AD4eEf06727C3F724954e9625186D7c",
  KISHDoggo: "0xD3c038B60f6e7c0F91BbE27c4fD448B76dcAd036",
  Alisson: "0x2A47E3D4C28D6E43aDe562641790f4C325B892AB",
  Silver: "0x62fDA40114f65DBd72F1c886C072820bC1A90818",
  Choper: "0x40aBe939F5B7CC034934DAb24146C4fc8647D240",
  Choper2: "0x31C90590c590Bc96aC827b7d1B64333dBfAfac54",
  Choper3: "0xC6fF38D110dff3452573d1FE467D20248C6AdA19",
  Choper4: "0xe277D637C3445d6B6536f0A79C11abE70D40F7E9",
  Ecko: "0x2941ed7E1Ff6a1AEC8f3831F9d7CeE059ECF3b3a",
  RadiantThunder: "0x5b8393Bff96DCc714431bc6F898Af5054f72Ef0f",
  Daveful: "0xeb727065b08abc5830a406899113923DB8D4dF7d",
  Tamires: "0xf2CD3EA1E59812c88337bd233fFfDBf19c66e24A",
  Lacatus: "0x4Aef834A46653801E9E36a2a2fE7d2D94f1F1AD6",
  Cryptofrito: "0xe8b00D5f62B8764B45D50C8c2Fc346e2d0cA5Ee6",
  CaioBrasil: "0x3489A4886b4D046fc6A98De156c2ccaCA30dB741",
  Edut0s: "0x3Cf31c41df45A106094eAFA72B0d6106a644BF70",
  Kalash: "0x72B6f189B19D838eD7BA7e5Ce00906d8fDc503A9",
  Blake9809: "0xE84dEfC10DD049aE4978bad85d63dd78822e80d2",
  Lisboa: "0x88ff38AEe331124cfe119480197dD286E4423bB4",
  LilaC: "0x5E7B85142E9f8532e8aB303dc296D3cB7a21e7D8",
  Jukie: "0x14Ca635bfAA39C81617AC946e9a19643dEcc6879",
  Alin: "0x51879eEc09685B5B33A5Ac7B5753cdD28C977ef8",
  Kings: "0x389DE5dA4249be7cE450E1bAD27b89FC73468155",
  Kings2: "0x5b1cD8C1a1bADcc27431a275E43473F2Faf4D742",
  Dylzhavingfun: "0xDdCe0bc2D2f1C1985b5ecc154180C5b57D12B0f8",
  Dylzhavingfun2: "0xb08c57893B53603D07589313FA85552d5DE56F05",
  Cm: "0x5BDD78A4A481fD67D6bE13b493cA030f22F29b31",
  Mullet: "0x15635Be08Ad4352fCE1638570C4E18cF58b9001a",
  NetheriteHoe: "0x9131B0d8144f14a7EFd9cd1c4b5aA0540751A0Bf",
  Ollie: "0x4647355322e36D427A89F6958A6e8848318dD1C5",
  Ollie2: "0x608a1807A368225C4820835c8f5BAcE71706dC14",
  Fitzxp: "0xa52d3dE9c8b0b21462F2C3A7a730C278ceC9eafC",
  Treasury: "0x44710845C6f4A075495672607cd858FA9cE73f05",
};

// Change this to the player you want to force:
const PLAYER_TO_FORCE = "none";
const shouldForcePlayer =
  window.location.href.indexOf("beastkingdom.io") !== -1 ? playersToCheckOn["none"] : playersToCheckOn[PLAYER_TO_FORCE];

export const PlayerContext = createContext({});

export const usePlayerContext = () => {
  return useContext(PlayerContext);
};

export const PlayerProvider = ({ children }) => {
  const { isReady, signer, provider, execFunction, consult, getChainId } = useWeb3Context();

  const [loading, setLoading] = useState(null);
  const [isRegistered, setIsRegistered] = useState(null);
  const [player, setPlayer] = useState(null);

  const [_collections, setCollections] = useState(null);

  const [latestFecthedBeastsIds, setLatestFecthedBeastsIds] = useState(null);
  const [latestFecthedBeasts, setLatestFecthedBeasts] = useState(null);

  const fetchPlayerData = useCallback(async () => {
    if (!isReady) return;
    if (!signer) return;
    if (window.location.href.indexOf("kingdom-bridge") !== -1 || window.location.href.indexOf("exit-bridge") !== -1)
      return;

    const actualPlayerAddress = shouldForcePlayer ? shouldForcePlayer : signer.address;
    if (!actualPlayerAddress || actualPlayerAddress === "0x" || loading) return;
    console.log("Fetching player data for signer", signer.address);

    setLoading(true);

    let everything;
    try {
      everything = await consult({
        contractName: "Fetch",
        functionName: "getEverything",
        functionParams: [actualPlayerAddress],
      });

      if (!everything || everything?.length === 0) {
        console.log("PlayerContext :: Fetching player data error");
        setLoading(false);
        return;
      }
    } catch (e) {
      everything = [];
    }
    if (!everything || everything?.length === 0 || everything.indexOf("Error: ") !== -1) {
      console.log("PlayerContext :: Fetching player data error");
      setLoading(false);
      return;
    }

    const playerTemp = new PlayerModel(actualPlayerAddress, everything, await provider.getBlockNumber(), Date.now());

    if (playerTemp.ingameBalances[2]?.length < 21) {
      playerTemp.ingameBalances[2][19] = 0;
      playerTemp.ingameBalances[2][20] = 0;
      playerTemp.ingameBalances[2][21] = 0;
      playerTemp.ingameBalances[2][22] = 0;
      playerTemp.ingameBalances[2][23] = 0;
      playerTemp.ingameBalances[2][24] = 0;
      playerTemp.ingameBalances[2][25] = 0;
    }

    try {
      const isMerchant = await consult({
        contractName: "Marketplace",
        functionName: "hasRole",
        functionParams: ["0x3c4a2d89ed8b4cf4347fec87df1c38410f8fc538bf9fd64c10f2717bc0feff36", actualPlayerAddress],
      });

      playerTemp.set("isMerchant", isMerchant);
    } catch (e) {
      console.warn("Error checking if player is merchant", e.message);
    }

    const koziBalance = await Rpc.fetchKoziBalanceOf(actualPlayerAddress);
    playerTemp.set("koziBalance", BigNumber(koziBalance));

    setPlayer(playerTemp);

    console.log("Player fetched", playerTemp);

    setIsRegistered(playerTemp?.info?.registeredAt > 0);
    setCollections(null);
    setLoading(false);
  }, [isReady, signer, consult]);

  useEffect(() => {
    fetchPlayerData();
  }, [isReady, signer]);

  async function transfer(to, bct, eBct, resources) {
    if (!isReady) return;

    await execFunction({
      contractName: "Farm",
      functionName: "transfer",
      functionParams: [to, bct, eBct, resources],
      successMessage: "Transfer successfull",
      errorList: [
        {
          reason: "Panic due to OVERFLOW(17)",
          message: "Insufficient funds",
        },
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: "/balances",
    });
  }

  async function fetchCollections(externalCollectionsToo = true) {
    if (!isReady) return;
    if (!player) return;
    if (_collections) return _collections;

    const collectionsResult = await consult({
      contractName: "DnaManager",
      functionName: "getCollectionsByAccount",
      functionParams: [player.address],
    });

    const collections = [];
    for (let i = 0; i < collectionsResult[0].length; i++) {
      const nfts = collectionsResult[1][i].map((nftData, index) => {
        const nft = new NftModel(nftData);
        return nft;
      });

      // Order nfts: if .isDead, put it at the end; then, the higher the nft.id, the higher the position
      nfts.sort((a, b) => {
        if (a.isDead && !b.isDead) return 1;
        if (!a.isDead && b.isDead) return -1;

        // Rule 2: NFTs with the highest farmPerBlock should come first
        const farmPerBlockDiff = new BigNumber(b.farmPerBlock).minus(a.farmPerBlock);
        if (!farmPerBlockDiff.isZero()) return farmPerBlockDiff.toNumber();

        if (a.id > b.id) return -1;
        if (a.id < b.id) return 1;
        return 0;
      });

      collections.push(new CollectionModel(collectionsResult[0][i], nfts, false));
    }

    // create a reference with all the collection IDs that we fetched:
    const collectionsIds = collections.map((collection) => collection.id);

    if (externalCollectionsToo) {
      // we're going to search for external collections too
      // that means we're going through all the nfts and check if they're in a collection
      // after we have a list of all external collections,
      // if the collection that they're in is not in the collections list, we fetch it and add it

      const externalCollectionsIds = [];
      player.nfts.forEach((nft) => {
        if (
          nft.collection !== 0 &&
          !externalCollectionsIds.includes(nft.collection) &&
          !collectionsIds.includes(nft.collection)
        ) {
          console.log(`Found NFT with external collection:`, nft);
          externalCollectionsIds.push(nft.collection);
        }
      });

      if (externalCollectionsIds.length > 0) {
        const externalCollectionsResult = await consult({
          contractName: "DnaManager",
          functionName: "getCollections",
          functionParams: [externalCollectionsIds],
        });

        if (externalCollectionsResult) {
          for (let i = 0; i < externalCollectionsResult[0].length; i++) {
            const nfts = externalCollectionsResult[1][i].map((nftData, index) => {
              const nft = new NftModel(nftData);
              return nft;
            });

            // Order nfts: if .isDead, put it at the end; then, the higher the nft.id, the higher the position
            nfts.sort((a, b) => {
              if (a.isDead && !b.isDead) return 1;
              if (!a.isDead && b.isDead) return -1;

              const farmPerBlockDiff = new BigNumber(b.farmPerBlock).minus(a.farmPerBlock);
              if (!farmPerBlockDiff.isZero()) return farmPerBlockDiff.toNumber();

              if (a.id > b.id) return -1;
              if (a.id < b.id) return 1;
              return 0;
            });

            collections.push(
              new CollectionModel(
                externalCollectionsResult[0][i],
                nfts,
                externalCollectionsResult[0][i].creator.toLowerCase() !== player.address.toLowerCase()
              )
            );
          }
        }
      }
    }

    setCollections(collections);

    return collections;
  }

  function clearLastFechedBeasts() {
    setLatestFecthedBeastsIds(null);
    setLatestFecthedBeasts(null);
  }

  async function fetchBeasts(nftIds) {
    if (nftIds.length === 0) return [];

    if (nftIds.toString() === latestFecthedBeastsIds?.toString()) {
      return latestFecthedBeasts;
    }
    if (!isReady) return;

    const rawList = await consult({
      contractName: "Fetch",
      functionName: "getBeasts",
      functionParams: [nftIds],
    });

    if (!rawList || rawList.indexOf("Error: ") !== -1) {
      return;
    }

    setLatestFecthedBeastsIds(nftIds);

    const beasts = [];
    for (let i = 0; i < rawList.length; i++) {
      const nftData = rawList[i];
      if (nftData) {
        const beast = new NftModel(nftData);
        beasts.push(beast);
      }
    }

    setLatestFecthedBeasts(beasts);

    console.log(`PlayerContext :: Fetched ${beasts.length} beasts`);

    return beasts;
  }

  async function signMessage(message) {
    const messageHash = shaMessage(message);

    const signature = await signer.signMessage(messageHash).catch((e) => {
      console.log(e.message);
      return null;
    });

    return signature;
  }

  async function proveIdentity(discordId) {
    if (!player) {
      console.log("PlayerContext :: No player data. Aborting proveIdentity.");
      return false;
    }

    const checksumAddress = ethers.getAddress(player.address);
    const playerSig = await signMessage(`${checksumAddress}${discordId}`);

    const body = {
      address: checksumAddress,
      discordId,
      playerSig,
    };

    console.log(">> body formed:", body);

    const result = await axios
      .post("https://fw6gclbtpd.execute-api.us-east-2.amazonaws.com/verifyAddress", body)
      .catch((e) => {
        console.error(e.response);

        if (e?.response?.data?.message === "Player not found.") {
          toast.warning(`Player not found. Please restart the verification process on Discord.`, {
            type: toast.TYPE.WARNING,
            autoClose: 5000,
          });

          return null;
        } else if (
          e?.response?.data?.message ===
          "You have already verified the same address. You don't need to do anything else. :)"
        ) {
          // toast:
          toast.warning(
            `This address is already linked to your Discord account. You don't need to do anything else about that. :)`,
            {
              type: toast.TYPE.WARNING,
              autoClose: 10000,
            }
          );

          return null;
        }

        // toast:
        toast.warning(`Error: ${e.response.data.message}`, {
          type: toast.TYPE.WARNING,
          autoClose: 5000,
        });

        return null;
      });

    if (!result) {
      return false;
    }

    console.log(">> result:", result);
    return true;
  }

  async function claim(_claim) {
    if (!isReady) return;

    const success = await execFunction({
      contractName: "Vault",
      functionName: "claim",
      functionParams: [_claim.digest, _claim.claimData, _claim.signature],
      successMessage: "Claim successfull",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
    });

    return success;
  }

  async function withdraw() {
    if (!isReady) return;

    const success = await execFunction({
      contractName: "Farm",
      functionName: "withdraw",
      functionParams: [player.info.bctToClaim],
      successMessage: "Withdraw successfull",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
    });

    return success;
  }

  async function stash(howMuch) {
    if (!isReady) return;

    const success = await execFunction({
      contractName: "Stasher",
      functionName: "stash",
      functionParams: [howMuch, false],
      allowance: {
        tokenContractName: "BCT",
        addressToAllow: config[ENV].Stasher.address,
        allowedContractName: "BK Stasher",
        allowedAssetDescription: `BCT in your wallet`,
        successMessage: `Allowance given to BK Stasher`,
      },
      successMessage: "Stash successfull. Please reload the website to see your updated Stasher Level.",
      errorList: [
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
    });

    return success;
  }

  async function unstash() {
    if (!isReady) return;

    const success = await execFunction({
      contractName: "Stasher",
      functionName: "unstash",
      functionParams: [],
      successMessage: "Unstash successfull. Please reload the website to see your updated Stasher Level.",
      errorList: [
        {
          reason: "COOLDOWN",
          message:
            "You're too early to unstash. If you have recently stashed or unstashed BCT, please reload the website to refresh your stasher level and lock timer.",
        },
      ],
    });

    return success;
  }

  async function fetchIsBeastmaster() {
    if (!isReady) return false;
    if (!player) return false;

    const merchantData = await consult({
      contractName: "Marketplace",
      functionName: "getMerchant",
      functionParams: [player.address],
    });

    const poolFuelSellScore = BigNumber(merchantData[2].toString());
    const beastmasterScore = parseFloat(poolFuelSellScore.div(1e19).toFixed(2));

    return beastmasterScore >= 10;
  }

  async function buyEbct(amount) {
    if (!isReady) return false;
    if (!player) return false;

    const success = await execFunction({
      contractName: "Forge",
      functionName: "buyEbct",
      functionParams: [amount],
      allowance: {
        tokenContractName: "Stabletoken",
        addressToAllow: config[ENV].Forge.address,
        allowedContractName: "The Forge",
        allowedAssetDescription: `USDC in your wallet`,
        successMessage: `Allowance given to The Forge`,
      },
      successMessage:
        "Transaction successfull, you have just bought eBCT! Please reload the website if your balance doesn't update automatically.",
      errorList: [
        {
          reason: "77",
          message: "This feature is not available yet.",
        },
      ],
    });

    return success;
  }

  async function meltResources(resourceIndexes, amountsInUnits, apeToolsUsed) {
    if (!isReady) return false;
    if (!player) return false;

    // SOL: function meltMany(uint256[] memory resourceIndexes, uint256[] memory amountsInUnits, uint256 apeToolsUsed)
    const success = await execFunction({
      contractName: "Forge",
      functionName: "meltMany",
      functionParams: [resourceIndexes, amountsInUnits, apeToolsUsed],
      successMessage:
        "Transaction successfull, you have created Crafting Powder! Please reload the website if your balance doesn't update automatically.",
      errorList: [
        {
          reason: "77",
          message: "This feature is not available yet.",
        },
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: "/balances",
    });

    return success;
  }

  async function craftResources(resourceIndexes, amountsInUnits, apeToolsUsed, eBctToCraft) {
    if (!isReady) return false;
    if (!player) return false;

    // SOL: function craftMany(uint256[] memory resourceIndexes, uint256[] memory amountsInUnits, uint256 apeToolsUsed)
    const success = await execFunction({
      contractName: "Forge",
      functionName: "craftMany",
      functionParams: [resourceIndexes, amountsInUnits, apeToolsUsed, eBctToCraft],
      successMessage:
        "Transaction successfull, you have crafted resources! Please reload the website if your balance doesn't update automatically.",
      errorList: [
        {
          reason: "77",
          message: "This feature is not available yet.",
        },
        {
          reason: "noFunds",
          message: `Insufficient funds`,
        },
      ],
      navigateOnSuccess: "/balances",
    });

    return success;
  }

  return (
    <PlayerContext.Provider
      value={{
        fetchPlayerData,
        player,
        isRegistered,
        loading,
        transfer,
        fetchCollections,
        fetchBeasts,
        signMessage,
        proveIdentity,
        claim,
        withdraw,
        stash,
        unstash,
        fetchIsBeastmaster,
        buyEbct,
        meltResources,
        craftResources,
        clearLastFechedBeasts,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
};
