const menus = [
  {
    id: 1,
    name: "Play",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "🤝 Register",
        links: "/register",
      },
      {
        id: 2,
        sub: "🐾 Kingdom Quests",
        links: "/kingdom-quests",
      },
      {
        id: 3,
        sub: "➰ The Forge",
        links: "/forge",
      },
      /*
      {
        id: 4,
        sub: "🥳 Prosperity Wars",
        links: "/prosperity-wars",
      },
      {
        id: 5,
        sub: "🍀 Fur & Fortune",
        links: "/fur-and-fortune",
      },
      {
        id: 6,
        sub: "🧟‍♂️ Gravedance",
        links: "/gravedance",
      },
      {
        id: 7,
        sub: "⚰️ Underworld Legacy",
        links: "/underworld-legacy",
        disabled: false,
      },
      */
    ],
  },
  {
    id: 2,
    name: "Inventory",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "🐮 Beasts",
        links: "/beasts",
      },
      {
        id: 2,
        sub: "💎 Balances",
        links: "/balances",
      },
      {
        id: 3,
        sub: "🥋 Masteries",
        links: "/masteries",
      },
      {
        id: 4,
        sub: "🐣 Mint",
        links: "/mint",
      },
      {
        id: 5,
        sub: "🎎 Collections",
        links: "/collections",
      },
      {
        id: 6,
        sub: "🤑 Withdraw",
        links: "/withdraw",
      },
      {
        id: 7,
        sub: "💰 Stash",
        links: "/stash",
      },
    ],
  },
  {
    id: 3,
    name: "Buy and Sell",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "🧿 KOZI: Gas Token",
        links: "/kozi-pool",
      },
      {
        id: 2,
        sub: "🦍 BCT: BK Token",
        links: "/bct-pool",
      },
      /*
      {
        id: 3,
        sub: "⚡ eBCT: Ingame Cash",
        links: "/buy-ebct",
      },
      */
      {
        id: 4,
        sub: "🎪 P2P Marketplace",
        links: "/marketplace/buy-resources",
      },
    ],
  },
  {
    id: 4,
    name: "Kingdom Chain",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "🎼 Travel Song",
        links: "/travelsong",
      },
      {
        id: 2,
        sub: "🦊 Add Kingdom Chain",
        links: "/add-kingdom-chain",
      },
      {
        id: 3,
        sub: "👑 Top Kozi Holders",
        links: "/kozi-holders",
      },
      {
        id: 4,
        sub: "🏆 Trophies & Tickets",
        links: "/trophies-and-tickets",
      },
      /*
      {
        id: 5,
        sub: "🔗 Kingdom Bridge",
        links: "/kingdom-bridge",
      },
      */
      {
        id: 5,
        sub: "👜 Exit Bridge",
        links: "/exit-bridge",
        disabled: false,
      },
      {
        id: 6,
        sub: "📃 Whitepaper",
        links: "/whitepaper",
      },
      {
        id: 7,
        sub: "📑 Smart Contracts",
        links: "/smart-contracts",
      },
    ],
  },
  {
    id: 5,
    name: "Community",
    links: "#",
    namesub: [
      {
        id: 1,
        sub: "🎓 Quick Start",
        links: "/quick-start",
      },
      {
        id: 2,
        sub: "🎭 Discord Community",
        links: "/discord",
      },
      {
        id: 3,
        sub: "🦖 All Kingdom Beasts",
        links: "/beast/-1",
      },
      {
        id: 4,
        sub: "🐾 All Kingdom Quests",
        links: "/all-kingdom-quests",
      },

      {
        id: 5,
        sub: "😴 Late Migration",
        links: "/late-migration",
      },
    ],
  },
];

export default menus;
